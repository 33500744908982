import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => (
  <Layout pageHeaderText="Contact" pageHeaderImage="mountains.png">
    <SEO title="Contact" />
    <h2 style={{textAlign:"center", fontSize:"3em", marginTop:"1rem", marginBottom:"2rem"}}>Reach Out!</h2>
    <h3 style={{textAlign:"center", maxWidth:"960px", margin:"0 auto"}}>
        <a style={{textDecoration:"none"}} href="mailto: devduckcontact@gmail.com">devduckcontact@gmail.com</a>
    </h3>
    <p style={{textAlign:"center", maxWidth:"960px", margin:"0 auto", marginTop:"2rem"}}>
        You can also find me on <a href="https:/www.youtube.com/c/devduck" target="_blank" rel="noopener noreferrer">YouTube</a> and <a href="https://twitter.com/_devduck" target="_blank" rel="noopener noreferrer">Twitter</a>.  And maybe Discord soon?
    </p>
    <Img style={{margin:"2rem 0"}} fluid={data.pcImage.childImageSharp.fluid} alt="DevDuck's Desk"/>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    pcImage: file(relativePath: { eq: "pc.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
